<template>
  <div class="login_box main">
    <div class="banner_box">
      <div class="swiper_box">
        <img src="@/assets/images/banner_login.jpg" />
      </div>
    </div>
    <div class="reg_box">
      <div class="container">
        If you already have an account for ECI, please log in. Otherwise, you
        can create a new account. <span @click="toReg">Register Here</span
        ><br />
        If you want to join our membership, please also
        <span @click="toReg">Register Here</span>.
      </div>
    </div>
    <div class="container">
      <div class="login_form">
        <div class="login_cont">
          <div class="line">
            <div class="title">* Email address:</div>
            <input class="inp" v-model="member_email" />
          </div>
          <div class="line">
            <div class="title">* Password:</div>
            <input
              class="inp"
              v-model="password"
              show-password
              type="password"
            />
          </div>
          <div class="line">
            <div class="creat" @click="toReg">Creat account</div>
            <div class="forget" @click="toForget">Forget password？</div>
          </div>
        </div>
        <div class="tips">
          By clicking Login you acknowledge and agree that you have read,
          understood and agree to be bound by our Terms & Conditions.
        </div>
        <div class="check_box">
          <input type="checkbox" class="check" v-model="isCheck" />
          <div class="text">Remember me on this device</div>
        </div>
        <button class="submit button" @click="login">LOGIN</button>
      </div>
    </div>
  </div>
</template>

<script>
import { do_login } from '@/api/reg.js';
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      isCheck: false,
      member_email: '',
      password: '',
    };
  },
  created() {},
  methods: {
    toForget() {
      this.$router.push({
        name: 'Forget',
      });
    },
    toReg() {
      this.$router.push({
        name: 'Register',
      });
    },
    login() {
      if (this.member_email == '' || this.password == '') {
        this.$message({
          type: 'warning',
          message: 'User ID and Password fields cannot be left empty.',
        });
      } else {
        do_login({
          member_email: this.member_email,
          password: this.password,
        }).then(res => {
          console.log(res);
          if (res.errcode == 2) {
            //成功
            console.log(this.isCheck);
            if (this.isCheck) {
              this.$store
                .dispatch('login_session', this.member_email)
                .then(() => {
                  console.log('login ok');
                  this.$router.replace({
                    name: 'Index',
                  });
                });
            } else {
              this.$store.dispatch('login', this.member_email).then(() => {
                console.log('login ok');
                this.$router.replace({
                  name: 'Index',
                });
              });
            }
          } else {
            this.$message({
              type: 'warning',
              message: res.errmsg,
            });
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.reg_box {
  padding: 60px 0;
  height: 180px;
  background-color: #eeeeee;
  text-align: center;
  span {
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
  }
}
.login_form {
  margin-top: 80px;
  .login_cont {
    width: 600px;
    margin: 0 auto 50px auto;
    .line {
      overflow: hidden;
      margin-bottom: 15px;
      line-height: 40px;
      .title {
        float: left;
        width: 150px;
      }
      .inp {
        float: left;
        border: 1px solid #000;
        width: 420px;
        padding: 0 10px;
      }
      .creat {
        float: left;
        margin-left: 150px;
        cursor: pointer;
      }
      .forget {
        float: right;
        cursor: pointer;
      }
    }
  }
  .tips {
    text-align: center;
  }
  .check_box {
    width: 230px;
    margin: 30px auto;
    overflow: hidden;
    .check {
      float: left;
      margin-top: 14px;
    }
    .text {
      float: right;
    }
  }
  .submit {
  }
}
@media (max-width: 750px) {
  .login_form {
    margin-top: 80px;
    .login_cont {
      width: 100%;
      margin: 0 auto 50px auto;

      .line {
        overflow: hidden;
        margin-bottom: 15px;
        line-height: 40px;
        .title {
          float: none;
          width: 100%;
        }
        .inp {
          float: none;
          border: 1px solid #000;
          width: 90%;
          padding: 0 10px;
        }
        .creat {
          float: left;
          margin-left: 0;
        }
        .forget {
          float: right;
        }
      }
    }
  }
}
</style>
