import request from '@/utils/request.js';
const BASEURL = process.env.VUE_APP_BASE_URL;

//获取图片上传签名
export function get_aliyun_sign(params) {
  return request({
    url: BASEURL + '/Home/Aliyun/getsign',
    method: 'GET',
    params,
  });
}
//注册
export function do_register(params) {
  return request({
    url: BASEURL + '/Home/Login/do_register',
    method: 'POST',
    params,
  });
}
//登录
export function do_login(params) {
  return request({
    url: BASEURL + '/Home/Login/do_login',
    method: 'POST',
    params,
  });
}
//忘记密码
export function resetPwd(params) {
  return request({
    url: BASEURL + '/Home/Login/resetPwd',
    method: 'POST',
    params,
  });
}
//获取验证码
export function verifyCode(params) {
  return request({
    url: BASEURL + '/Home/Login/verifyCode',
    method: 'POST',
    params,
  });
}
